@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@200;300&family=Outfit:wght@400&display=swap');
body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #260841;
}

h1 {
  font-size: 64px;
  font-weight: 700;
  color: #fff;
  margin: "0px";
}

h2 {
  font-size: 52px;
  font-weight: 600;
  color: #fff;
}

p {
  font-size: 21px;
  font-weight: 400;
  color: #fff;
}

code {
  font-family: 'Outfit', sans-serif;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.349);
}

.navbar {
  height: 89px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #260841;
}

.nav-item {
  font-size: 18px;
  padding-left: 6px;
  font-weight: 500;
}

.nav-item:hover {
  font-size: 18px;
  padding-left: 6px;
  font-weight: 600;
}

.nav-link{
  color: #fff;
}

.nav-link:hover{
  color: #fff;
}

.navbar-brand{
  color: #fff;
  font-size: 27px;
  padding-right: 4px;
  font-weight: 600;
}

.navbar-brand:hover {
  color: #fff;
  font-size: 27px;
  padding-right: 4px;
  font-weight: 600;
}

.first-section {
  display: flex;
  overflow: hidden;
  align-items: center;
  max-width: 1120px;
  margin: 0px;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 69px;
  @media (min-width: 866px) {
    flex-direction: row;
  }
}

.second-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1120px;
  margin-top: 125px;
  margin-bottom: 125px;
  flex-direction: column;
  @media (min-width: 866px) {
    flex-direction: row;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #937BA1;
  color: white;
  font-size: 18px;
  font-weight: 600;
  height: fit-content;
  width: fit-content;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #B094B9;
}

.button-black {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  font-size: 18px;
  font-weight: 600;
  height: fit-content;
  width: fit-content;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-black:hover {
  background-color: #fff;
  color: black;
}

.gradient-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 125px;
  margin-bottom: 125px;
  max-width: 1120px;
  height: 500px; 
  border-radius: 10px;
  background: linear-gradient(to bottom right, #4E2A84, #B6ACD1);
  overflow: hidden;
  @media (min-width: 951px) {
    height: 300px;
  }
}

.image-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1120px;
  margin-top: 50px;
  margin-bottom: 125px;
  flex-direction: column;
}

hr.solid {
  border-top: 3px solid #fff;
}

.image-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  @media (min-width: 866px) {
    flex-direction: row;
  }
}

.image-and-text {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}